var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tilePanel" }, [
    _c("div", { staticClass: "tilePanel__imgContainer" }, [
      _c("img", {
        directives: [
          {
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.beatImageOrProducerLogo(_vm.beat),
            expression: "beatImageOrProducerLogo(beat)",
          },
        ],
        staticClass: "tilePanel__img",
      }),
      _c(
        "div",
        { staticClass: "tilePanel__actions" },
        [
          _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.beatIsLiked ? "Unlike Beat" : "Like Beat",
                expression: " beatIsLiked ? 'Unlike Beat' : 'Like Beat'",
              },
            ],
            staticClass: "tilePanel__likeIcon",
            class: { isLiked: _vm.beatIsLiked },
            attrs: { name: "heart" },
            nativeOn: {
              click: function ($event) {
                return _vm.toggleLike(_vm.beat)
              },
            },
          }),
          _c(
            "a",
            {
              staticClass: "tilePanel__playIcon",
              on: { click: _vm.togglePlayStatus },
            },
            [
              _c("icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.isAudioPaused || _vm.beat.id !== _vm.beatPlaying.id,
                    expression: "isAudioPaused || beat.id !== beatPlaying.id",
                  },
                ],
                staticClass: "icon icon-play",
                attrs: { name: "play", width: "52", height: "52" },
              }),
              _c("icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.isAudioPaused && _vm.beat.id === _vm.beatPlaying.id,
                    expression: "!isAudioPaused && beat.id === beatPlaying.id",
                  },
                ],
                staticClass: "icon icon-pause",
                attrs: { name: "pause", width: "52", height: "52" },
              }),
            ],
            1
          ),
          _c("icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Demo Download",
                expression: "'Demo Download'",
              },
            ],
            staticClass: "tilePanel__downloadIcon",
            class: { isDisabled: !_vm.beat.demo_download_allowed },
            attrs: { name: "download" },
            on: {
              click: function ($event) {
                return _vm.demoDownloadBeat(_vm.beat)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "tilePanel__details" },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.beat.isPromoted,
                expression: "beat.isPromoted",
              },
            ],
            staticClass: "tilePanel__promoted",
          },
          [_vm._v("Ad")]
        ),
        _c(
          "router-link",
          {
            staticClass: "tilePanel__details--track-artist-type",
            attrs: {
              to: {
                name: "ArtistType",
                params: { slug: _vm.beat.artist_type.slug },
              },
            },
          },
          [_vm._v(_vm._s(_vm.beat.artist_type.name))]
        ),
        _c("div", { staticClass: "tilePanel__details--track-title" }, [
          _vm._v(_vm._s(_vm.beat.name)),
          _vm.beat.sampled
            ? _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "This beat contains uncleared samples",
                      expression: "'This beat contains uncleared samples'",
                    },
                  ],
                  staticClass: "tilePanel__copyright",
                },
                [_c("icon", { attrs: { name: "copyright" } })],
                1
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "tilePanel__details--track-author" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "Producer",
                    params: { slug: _vm.beat.producer.slug },
                  },
                },
              },
              [_vm._v(_vm._s(_vm.beat.producer.display_name))]
            ),
          ],
          1
        ),
        _c(
          "a",
          {
            staticClass: "btn btn--sm btn--primary",
            on: {
              click: function ($event) {
                return _vm.SET_ACTIVE_MODAL({
                  modal: _vm.ModalType.LicenseOptionSelection,
                  payload: _vm.beat,
                })
              },
            },
          },
          [_vm._v(_vm._s(_vm.beatAlreadyInCart ? "In Cart" : "+ ADD"))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }