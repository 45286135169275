var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tilePanel--placeholder" }, [
      _c("div", { staticClass: "tilePanel__imgContainer" }, [
        _c("div", { staticClass: "tilePanel--img" }),
      ]),
      _c("div", { staticClass: "tilePanel__details" }, [
        _c("div", { staticClass: "tilePanel__details--track-artist-type" }),
        _c("div", { staticClass: "tilePanel__details--track-title" }),
        _c("div", { staticClass: "tilePanel__details--track-author" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }