

























import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
// mixins
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { BeatAlreadyInCart } from '@/mixins/beat-already-in-cart'

import { BeatIsFullyBoughtAndBeatIsLiked } from '@/mixins/beat-is-liked'

// const
import { ModalType, ModalStore } from '~/store/modal'
import { PlayerStore } from '~/store/player'
import { BeatStore } from '~/store/beat'
import { UserStore } from '@/store/user'

@Component
export default class TilePanel extends Mixins(BeatIsFullyBoughtAndBeatIsLiked, BeatAlreadyInCart, BeatImageOrProducerLogo) {
    @Prop({ type: Object }) beat: IBeat
    @Prop({ type: Number }) index: number
    @Prop({ type: Array }) loadedBeats: IBeat[]

    ModalType = ModalType

    @PlayerStore.State('isAudioPaused') isAudioPaused: boolean
    @PlayerStore.Getter('beatPlaying') beatPlaying: IBeat

    @BeatStore.Action('demoDownloadBeat') demoDownloadBeat: () => void
    @UserStore.Action('toggleLike') toggleLike: () => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: () => void

    togglePlayStatus() {
        this.$store.dispatch('player/togglePlayStatus', {
            beats: this.loadedBeats,
            index: this.index,
            route: this.$route.fullPath
        })
    }
}
